import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment as env } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RiesgoService {

  readonly endpoint = env.endpoint;
  constructor( protected http: HttpClient) { }

  listByCia( ciaId ) {
    let url = this.endpoint + '/riesgo/cia/' + ciaId;
    return this.http.get( url ).pipe( map( (_: any) => _.data ) );
  }

  list() {
    let url = this.endpoint + '/riesgo/';
    return this.http.get( url ).pipe( map( (_: any) => _.data ) );
  }

  listByTasaAsegRiesgo( tasa, idCia, idRiesgo ) {
    let url = this.endpoint + '/tasa/list/' + tasa + '/'  + idCia + '/' + idRiesgo;
    return this.http.get( url ).pipe( map( (_: any) => _.data ) );
  }

  updateTasa( tasa ) {
    let url = this.endpoint + '/tasa/update';
    return this.http.put( url, tasa ).pipe( map( (_: any) => _.data ) );
  }

  getById( riesgoId: String ) {
    let url = this.endpoint + '/riesgo/' + riesgoId ;
    return this.http.get( url ).pipe( map( (_: any) => _.data ) );
  }
}
