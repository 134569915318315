import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Poliza } from 'src/app/models/poliza.model';
import { environment as env } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PolizaService {

  readonly endpoint = env.endpoint;

  constructor(public http: HttpClient) { }

  listAll(token: String) {
    let url = this.endpoint + '/poliza/?token=' + token;
    return this.http.get<Poliza[]>(url);
  }

  save(token: String, poliza: Poliza) {
    let url = this.endpoint + '/poliza/save';
    return this.http.post(url, poliza);
  }
}
