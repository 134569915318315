export class Slim {
    public constructor(
        public riesgo?: String,
        public aseguradora?: String,
        public cobertura?: String,
        public uso?: String,
        public valor?: String,
        public tasa?: String,
        public _id?: String
    ) { }
}
