import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanSeleccionComponent } from './plan-seleccion/plan-seleccion.component';

@NgModule({
    declarations: [
        PlanSeleccionComponent
    ],
    exports: [
        PlanSeleccionComponent
    ],
    imports: [ CommonModule ],
    providers: [],
})
export class ComponentsModule {}