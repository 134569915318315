import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Aseguradora } from 'src/app/models/aseguradora.model';
import { Planes } from 'src/app/models/planes.model';
import { DialogEnum } from 'src/app/services/commons/dialog.enum';
import { DialogService } from 'src/app/services/commons/dialog.service';
import { AseguradoraService, UsuarioService } from 'src/app/services/service.index';


@Component({
  selector: 'app-plan-seleccion',
  templateUrl: './plan-seleccion.component.html',
  styles: []
})
export class PlanSeleccionComponent implements OnDestroy {

  subscribers = Array<Subscription>();
  aseguradoras: Array<Aseguradora> = [];
  planes: Array<Planes>;
  planesSeleccionados: Array<Planes> = [];
  idxAseg = 0;

  @Output() planesOutput: EventEmitter<Array<Planes>> = new EventEmitter();
  
  constructor( 
    public _aseg: AseguradoraService,
    public _usu: UsuarioService,
    public _dlg: DialogService
  ) { 
    this.init();
  }

  ngOnDestroy() { 
    this.subscribers.forEach( sb => sb.unsubscribe() );
  }

  init(){
    this.subscribers.push( 
      this._aseg
      .listAll( this._usu.token )
      .subscribe( ( xhrData: Array<Aseguradora> ) => {
        this.aseguradoras = xhrData;
        this.listPlanes( this.idxAseg );
      }) );
  }

  listPlanes( idxAseg: number ){
    this.idxAseg = idxAseg;
    this.planes = JSON.parse( JSON.stringify( this.aseguradoras[ this.idxAseg ].planes ) );

    let idc = 0;

    while( idc < this.planes.length ){
      if( !this.isHasUserPlan( this.planes[ idc ].plan + '-' + this.planes[ idc ].producto ) ){
        this.planes.splice( idc, 1 );
        continue;
      }
      idc++;
    }

  }

  isHasUserPlan( plan: string ){
    console.log( 'Buscando' );
    const dataFilter = this._usu.usuario.planes_cot.filter( pln => pln == plan );
    // return dataFilter.length>0; // Se deben actualizar los planes para todos los usuarios
    return true; 
  }

  agregarPlan( idxPlan: number ) {

    // Buscando un unico plan por cia
    let isPlanAgredado = false;
    this.planesSeleccionados.forEach( plan => {

      if( plan.plan == this.planes[ idxPlan ].plan ) {
        isPlanAgredado = true;
        return;
      }

    });

    // Si no esta agregado, se agrega
    if( !isPlanAgredado ) {
      const plan = this.planes[ idxPlan ];
      plan.isWSDL = this.aseguradoras[ this.idxAseg ].cotizarWSDL;
      plan.idAseguradora = this.aseguradoras[ this.idxAseg ]._id;
      
      this.planesSeleccionados.push( plan );
      this.planesOutput.emit( this.planesSeleccionados );
    } else {
      this._dlg.alert( 'Atención', 'No se pueden repetir los planes', DialogEnum.WARNING );
    }

  }

  eliminarPlan( idxPlan ){
    this.planesSeleccionados.splice( idxPlan, 1 );
    this.planesOutput.emit( this.planesSeleccionados );
  }
  
}
