import { Component, OnInit } from '@angular/core';
import { CoberturaService, UsuarioService, RiesgoService, CommonsService, AseguradoraService, ParametroService } from '../../services/service.index';

import swal from 'sweetalert';
import { Slim } from 'src/app/models/slim.model';
import { VALOR_USO_VEHICULAR, GRUPO_SLIM, GRUPO_TASA } from '../../config/constants';

@Component({
  selector: 'app-coberturas',
  templateUrl: './coberturas.component.html',
  styles: []
})
export class CoberturasComponent implements OnInit {

  cobertura: any = {};
  riesgos: any = [];
  cias: Array<any> = [];
  usos: Array<any> = [];
  tiposSlip: Array<any> = [];
  planes: Array<any> = [];

  have_data = false;

  data_cobertura: Array<any> = [];
  riesgo: String = null;
  aseguradora: String = null;
  uso: String = null;
  plan: String = null;
  tabSelected: String = null;

  constructor( protected _cobertura: CoberturaService,
    protected _param: ParametroService,
    protected _aseg: AseguradoraService,
    protected _usuario: UsuarioService,
    protected _riesgo: RiesgoService,
    protected _c: CommonsService ) {
      _aseg.listAll( _usuario.token ).subscribe( _data => this.cias = _data );
      _param.listGroupChildren( 'USO_' + VALOR_USO_VEHICULAR ).subscribe( _data => this.usos = _data );
      // this.list_planes();
  }

  list_coberturas() {
    if ( this.isSelect() ) {
      this
      ._param
      .listGroupChilderWithOrden( GRUPO_SLIM, 'orden', 1 )
      .subscribe( _data => { 
        this.tiposSlip = _data;
        this.tabSelected = this.tiposSlip[0].nombre;
        this.list_slip( this.tabSelected );
      } );
    }
  }

  list_slip( tipo_slip ) {
    
    this.cobertura = [];
    this.tabSelected = tipo_slip;
    this._cobertura.list( tipo_slip,
            this.aseguradora,
            this.riesgo,
            this.uso,
            this.plan ).subscribe( data => this.cobertura = data );

  }

  list_planes() {
    this._param
    .listGroupChildren( GRUPO_TASA )
    .subscribe( xhrData => this.planes = xhrData );
  }

  isSelect(): boolean {
    this.have_data = ( this.aseguradora != null ) &&
                    ( this.riesgo != null ) &&
                    ( this.uso != null );
    return this.have_data;
  }

  ngOnInit() { }

  loadRiesgos( idCia ) {
    this.riesgo = null;
    this._riesgo.listByCia( idCia ).subscribe( data => {
      this.riesgos = data;
      this.list_coberturas();

    });
    this.planes = this.cias.filter( ( obj ) => obj._id == idCia )[0].planes;
  }

  registrarCobertura() {

    try {
      this._c.assertNotEmpty( this.aseguradora, 'Aseguradora no seleccionada' );
      this._c.assertNotEmpty( this.riesgo , 'Riesgo no seleccionado' );
      this._c.assertNotEmpty( this.uso , 'Usos no seleccionado' );

      let coberturas = [];

      console.log( this.data_cobertura );

      // tslint:disable-next-line:forin
      for ( let i in this.data_cobertura ) {
        console.log( i );
        coberturas.push( { codigo: this.cobertura.slim[i].nombre, valor: this.data_cobertura[i] } );
      }

      /*
      this._cobertura
      .saveSlim( new Slim( this.riesgo, this.aseguradora, coberturas, this.uso ) )
      .subscribe( data => { swal( 'Registramos con éxito todos los registros' , 'Atención', 'success'); }); */

    } catch ( e ) {
      swal( e.message , 'Atención', 'warning');
    }

  }

  saveData( value, i ) {

    let slimSave = new Slim( this.riesgo, this.aseguradora, this.cobertura[i].nombre, this.uso, value, this.plan );

    if ( this.cobertura[i].slip.length == 0 ) {
      this._cobertura
      .saveSlim( slimSave )
      .subscribe( data => console.log( 'Registrado con éxito' ) );
    } else {
      slimSave = new Slim( this.riesgo, this.aseguradora, this.cobertura[i].nombre, this.uso, value, this.plan, this.cobertura[i].slip[0]._id );
      this._cobertura
      .updateSlim( slimSave )
      .subscribe( data => console.log( 'Editado con éxito' ) );
    }

  }

}
