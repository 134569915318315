export const GRUPO_USO_VEHICULAR = 'USO_VEHICULAR';
export const GRUPO_TIPO_DOCUMENTO = 'TIPO_DOCUMENTO';
export const GRUPO_DEPARTAMENTO = 'DEPARTAMENTO';
export const GRUPO_PROVINCIA = 'PROVINCIA';
export const GRUPO_DISTRITO = 'DISTRITO';
export const GRUPO_TIPO_DATO_CLIENTE = 'TIPO_DATO_CLIENTE';

export const GRUPO_COBERTURA = 'COBERTURA';
export const GRUPO_COBERTURA_ADICIONAL = 'COBERTURA_ADICIONAL';
export const GRUPO_SERVICIOS_ASISTENCIAS = 'SERVICIOS_ASISTENCIAS';
export const GRUPO_DEDUCIBLES = 'DEDUCIBLES';
export const GRUPO_SLIM = 'SLIM';
export const GRUPO_TASA = 'TASA';
export const GRUPO_VARIABLES_GLOBALES = 'VARIABLES_GLOBALES';

export const VALOR_USO_VEHICULAR = 'VEHICULAR';

export const ACTIVO = 1;

export const CIA_RIMAC_ID = '5c3cffcf9c575a317cbaf904';


