import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modelo-seguro',
  templateUrl: './seguro.component.html',
})
export class MSeguroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
