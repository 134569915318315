import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Aseguradora } from 'src/app/models/aseguradora.model';
import { Cliente } from 'src/app/models/cliente.model';
import { Modelo } from 'src/app/models/modelo.model';
import {
    UsuarioService,
    ClienteService,
    ModeloService,
    ParametroService,
    CotizacionService,
    AseguradoraService,
} from 'src/app/services/service.index';

import { Cotizacion } from 'src/app/models/cotizacion.model';

import { CIA_RIMAC_ID, VALOR_USO_VEHICULAR } from 'src/app/config/constants';
import { GRUPO_USO_VEHICULAR } from 'src/app/config/constants';
import { GRUPO_TASA } from 'src/app/config/constants';
import { Parametro } from 'src/app/models/parametro.model';
import { Auto } from 'src/app/models/auto.model';
import { Router, ActivatedRoute } from '@angular/router';

import swal from 'sweetalert';
import { Fecha } from '../../models/fecha.model';
import { take } from 'rxjs/operators';
import { IntegracionService } from 'src/app/services/integracion/integracion.service';
import { RimacResAuto } from 'src/app/models/rimac_integracion/response/rimac_res_auto.model';
import { RimacResAutoMas } from 'src/app/models/rimac_integracion/response/rimac_res_automas.model';
import { Planes } from 'src/app/models/planes.model';

@Component({
    selector: 'app-ne-cotizacion',
    templateUrl: './ne-cotizacion.component.html',
    styleUrls: [],
})
export class NeCotizacionComponent implements OnInit {
    
    public isRimacOn = false;
    private idCotizacion: string = null;

    public nombresCliente = '';
    public nombreModelo = '';
    public isLoadingCotizacion = true;
    public nroDocumento = null;

    isSaving: boolean = false;
    isClientFind: boolean = false;
    isModeloFind: boolean = false;
    clientsFind: Array<Cliente> = [];
    modelosFind: Array<Modelo> = [];

    /* LISTAS GENERALES */
    aseguradoras: Array<Aseguradora> = [];
    usosVehiculares: Array<Parametro> = [];
    tasas: Array<Parametro> = [];

    tasaSelected: Parametro = {};
    cotizacion: Cotizacion = new Cotizacion();
    auto: Auto = new Auto();
    sumaAsegurada = null;

    // Variables de consulta
    public isFindingAuto = false;

    @ViewChild('txtSumaAsegurada')
    txtSumaAsegurada: ElementRef;

    minDate = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    };

    planesSeleccionados: Array<Planes> = [];

    planIndex = 0;
    isIntegracion = true;

    @ViewChild('anio') txtAnio: ElementRef;
    @ViewChild('modelo') txtModelo: ElementRef;

    constructor(
        public _cliente: ClienteService,
        public _modelo: ModeloService,
        public _usuario: UsuarioService,
        public _parametro: ParametroService,
        public _cotizacion: CotizacionService,
        public _asegurador: AseguradoraService,
        public _int: IntegracionService,
        public _router: Router,
        private _actRouter: ActivatedRoute
    ) {
        this.auto.asientos = 5;

        this.listUsos();

        this.isNewCotizacion()
            .then((succ) => {
                this._cotizacion
                    .getById(this._usuario.token, this.idCotizacion)
                    .pipe(take(1))
                    .subscribe((xhrCot: Cotizacion) => {
                        this.cotizacion = xhrCot;
                        this.cotizacion.aseguradoras = [];

                        this.nroDocumento = this.cotizacion.cliente.nro_documento;

                        this.nombresCliente = this.getNombreCliente();
                        this.listAseguradora();

                        this._modelo
                            .find(
                                this._usuario.token,
                                '_id',
                                this.cotizacion.auto.modelo
                            )
                            .subscribe((xhrModelo: Modelo) => {
                                this.auto = this.cotizacion.auto;
                                this.auto.modelo = xhrModelo;
                                this.cotizacion.auto.modelo = xhrModelo;
                                this.nombreModelo =
                                    this.cotizacion.auto.modelo.marca +
                                    ' - ' +
                                    this.cotizacion.auto.modelo.nombre;

                                this.buscarPlaca();
                                this.isLoadingCotizacion = false;
                            });
                    });
            })
            .catch((err) => {
                this.cotizacion.en_tramite = false;
                this.cotizacion.is_nuevo = false;
                this.cotizacion.aseguradoras = [];
                this.listAseguradora();
                this.isLoadingCotizacion = false;
            });
    }

    isNewCotizacion(): Promise<void> {
        return new Promise((res, rej) => {
            this._actRouter.params.subscribe((params) => {
                if (params['id'] !== undefined && params['id'] !== '0') {
                    this.idCotizacion = params['id'];
                    res();
                }

                rej();
            });
        });
    }

    ngOnInit() {}

    searchModelo($event, token) {
        if ($event.keyCode == 27) {
            this.isModeloFind = false;
            this.modelosFind = [];
            return;
        }

        this.searchModeloText($event.target.value, token);
    }

    searchModeloText(value, token) {
        if (value.length > 2) {
            if (this.isRimacOn) {
                this._modelo
                    .searchIntegracion(token, value)
                    .subscribe((data) => {
                        this.modelosFind = data;
                        this.isModeloFind = true;
                    });
            } else {
                this._modelo.search(token, value).subscribe((data) => {
                    this.modelosFind = data;
                    this.isModeloFind = true;
                });
            }
        }
    }

    listUsos() {
        this._parametro
            .listGroupChildren(GRUPO_USO_VEHICULAR)
            .subscribe((data) => {
                this.usosVehiculares = data;
                this.cotizacion.uso = this.usosVehiculares[0].nombre;
            });

        this._parametro.listGroupChildren(GRUPO_TASA).subscribe((data) => {
            this.tasas = data.sort( ( pre: Parametro, post: Parametro ) => {
                if( pre.nombre < post.nombre ){
                    return -1;
                }
                return 1
            });

            this.tasaSelected = this.tasas[0];
        });
    }

    listAseguradora() {
        this._asegurador.listAll(this._usuario.token).subscribe((data) => {
            this.aseguradoras = data;
            this.aseguradoras.forEach( (_ase) => this.planesSeleccionados[ _ase._id ] = '' );
        });
    }

    validarNuevo(value: number) {
        let currentYear = new Date().getFullYear();
        if (
            this.txtAnio.nativeElement.value !== '' &&
            this.cotizacion.is_nuevo
        ) {
            if (currentYear - value > 1) {
                swal(
                    'Atención',
                    'El año ingresado no aplica para nuevo, lo corregimos al año actual',
                    'warning'
                );
                this.txtAnio.nativeElement.value = currentYear;
            }
        }
    }

    cotizar() {

        if (this.validate_form()) {
            
            if (typeof this.auto.placa == 'undefined') {
                this.auto.placa = 'SIN DATO';
            }

            if (typeof this.auto.anio_fabricacion == 'undefined') {
                this.auto.anio_fabricacion = 2021;
            }

            // Pasando a mayusculas
            this.auto.placa = this.auto.placa.toUpperCase();
            this.auto.placa = this.auto.placa.replace(/\-/g, '');

            const planesRimac: Planes = this.getPlanAseguradora( CIA_RIMAC_ID );
            this.cotizacion.planesSeleccionados = this.planesSeleccionados;
            this.cotizacion.isIntegracion = this.isIntegracion;

            this.saveCotizacion();
        }
    }

    private getPlanAseguradora( idAseguradora: string ): Planes {
        const planSeleccionado = this.planesSeleccionados.filter( plan => plan.idAseguradora == idAseguradora );

        if ( planSeleccionado.length == 0 ) {
            return undefined;
        }
        
        // Obteniendo el primero registro del plan
        return planSeleccionado[0];
    }

    private isVeparmil() {
        const veparmilTasa = this.planesSeleccionados.filter( plan => plan.nombrePlan.search( /veparmil/i ) != -1 );
        return veparmilTasa.length > 0;
    }

    private saveCotizacion() {

        // Platinum
        this.tasaSelected = this.tasas[ 0 ];
        if ( this.isVeparmil() ) {
            this.tasaSelected = this.tasas[ 1 ];
        }

        this.isSaving = true;
        this.cotizacion.usuario_reg = this._usuario.usuario._id;
        this.cotizacion.auto = this.auto;
        this.cotizacion.producto = VALOR_USO_VEHICULAR;
        this.cotizacion.tasa = this.tasaSelected.nombre;
        this.planesSeleccionados.forEach( (plan: Planes) => this.cotizacion.aseguradoras.push( plan.idAseguradora ) );

        if( this.cotizacion.cliente instanceof Cliente ){

            this._cliente
            .search( this._usuario.token, this.cotizacion.cliente.nro_documento )
            .subscribe( xhrCliente => {
                
                if( xhrCliente.length == 0 ){
                    this._cliente
                    .save( this._usuario.token, this.cotizacion.cliente )
                    .subscribe(  (xhrNewClient: Cliente) => {
                        this.cotizacion.cliente = xhrNewClient._id;
                        this.persistCotizacion();
                    });
                }else{
                    this.cotizacion.cliente = xhrCliente[0]._id;
                    this.persistCotizacion();
                }

            });

        }else{
            this.persistCotizacion();
        }
        
    }


    private persistCotizacion(){
        
        this._cotizacion.save(this._usuario.token, this.cotizacion).subscribe(
            (_) => {
                this.isSaving = false;
                this._router.navigate(['/cotizacion/prev/' + _._id]);
            },
            (error) => {
                
                this.isSaving = false;
                let mensaje = error.error.mensaje;

                if( error.error.exception &&  error.error.exception.messages ){
                    mensaje = error.error.exception.messages[0];
                }

                swal( 'Atención', mensaje, 'error');
                console.log(error);
            }
        );
    }

    validate_form() {
        if (typeof this.cotizacion.cliente == 'undefined') {
            swal(
                'Atención',
                'Cliente es importante para generar la cotización',
                'error'
            );
            return false;
        }

        if (typeof this.auto.modelo == 'undefined') {
            swal(
                'Atención',
                'Modelo es importante para la cotización',
                'error'
            );
            return false;
        }

        return true;
    }

    buscarPlaca() {
        if (this.auto.placa.length == 0) {
            return;
        }

        this.isFindingAuto = true;
        this.auto.placa = this.auto.placa.replace(/-/g, '');

        this._int
            .autoObtener(this.auto.placa)
            .pipe(take(1))
            .subscribe((xhrAuto: RimacResAuto) => {
                const modeloPaso: Modelo = {
                    marca: xhrAuto.data.payload.nombreMarca,
                    modelo: xhrAuto.data.payload.nombreModelo,
                    nombre: xhrAuto.data.payload.nombreMarca,
                    tipo: xhrAuto.data.payload.nombreTipo,
                    clase: xhrAuto.data.payload.nombreClase,

                    marca_cod: xhrAuto.data.payload.codigoMarca,
                    modelo_cod: xhrAuto.data.payload.codigoModelo,
                    clase_cod: xhrAuto.data.payload.codigoClase,
                    tipo_cod: xhrAuto.data.payload.codigoTipo,
                };

                this.auto.modelo = modeloPaso;
                this.auto.anio_fabricacion =
                    xhrAuto.data.payload.anhoFabricacion;
                this.txtModelo.nativeElement.value = modeloPaso.marca.concat(
                    ' - ',
                    modeloPaso.modelo
                );

                this.buscarAutoAutoMas(
                    modeloPaso.modelo_cod,
                    modeloPaso.marca_cod,
                    this.auto.anio_fabricacion
                );

                this._modelo
                .find( this._usuario.token, 'modelo_cod', xhrAuto.data.payload.codigoModelo )
                .pipe( take(1) )
                .subscribe( xhrModelo => {
                    this.auto.modelo._id = xhrModelo._id;
                });
            });
    }

    buscarAutoAutoMas(modeloCod: string, marcaCod: string, anioFab: number) {
        this.isFindingAuto = true;

        this._int
            .autoMasObtener(modeloCod, marcaCod, anioFab)
            .pipe(take(1))
            .subscribe((xhrAutomas: RimacResAutoMas) => {

                if( typeof xhrAutomas.data.payload.valorActual != "undefined" ) {
                    this.cotizacion.suma_aseg = xhrAutomas.data.payload.valorActual;
                }
                this.isFindingAuto = false;

            });
    }

    changeSeguro(_id: string, _i: number, value) {
        let i = this.cotizacion.aseguradoras.findIndex((id) => _id === id);

        console.log( this.cotizacion.aseguradoras );

        if (i !== -1) {
            this.cotizacion.aseguradoras.splice(i, 1);
            this.planesSeleccionados[ this.aseguradoras[i]._id ] = '';
        } else {
            this.cotizacion.aseguradoras.splice(_i, 0, _id);
            this.planesSeleccionados[ _id ] = value;
        }
    }

    agregarVigenciaHasta() {
        const fechaPaso = this.cotizacion.desde;
        this.cotizacion.hasta = new Fecha(
            fechaPaso.year + 1,
            fechaPaso.month,
            fechaPaso.day
        );
    }

    isShowAseguradoras(): boolean {
        let isShowAseg: boolean = true;
        return isShowAseg;
    }

    seleccionarPlan( aseguradora, value ) {
        this.planesSeleccionados[ aseguradora ] = value;
    }

    cambiarTimon() {
        return (this.cotizacion.timon_cambiado = !this.cotizacion
            .timon_cambiado);
    }

    getNombreCliente() {
        if (this.cotizacion.cliente !== undefined) {
            if (this.cotizacion.cliente.razon_social === undefined) {
                return (
                    this.cotizacion.cliente.nombre +
                    ' ' +
                    this.cotizacion.cliente.apellido_paterno +
                    ' ' +
                    this.cotizacion.cliente.apellido_materno
                );
            }
            return this.cotizacion.cliente.razon_social;
        }
        return '';
    }
}
