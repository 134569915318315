import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ClienteService, UsuarioService } from 'src/app/services/service.index';
import { Cliente } from 'src/app/models/cliente.model';
import { IntegracionService } from 'src/app/services/integracion/integracion.service';

@Component({
  selector: 'app-cliente-search-text',
  templateUrl: './cliente-search-text.component.html',
  styleUrls: [ ]
})
export class ClienteSearchTextComponent implements OnInit {

  @Input() titulo_search = 'Cliente';
  @Input() section = 1;
  @Input() cliente: String = '';
  @Input() nroDocumento: string = null;
  @Output() emit_cliente: EventEmitter<Cliente> = new EventEmitter();

  @ViewChild('cliente') textoBuscado: ElementRef;

  @ViewChild('documento') documento: ElementRef;

  clientsFind: Array<Cliente> = [];
  isClientFind: boolean = false;

  constructor( public _cliente: ClienteService,
                public _int: IntegracionService,
               public _usuario: UsuarioService ) { }

  ngOnInit() {
    this.escribirCliente( this.cliente );

    if( this.nroDocumento != null ){
      this.searchByDNIRimac( this.nroDocumento );
      this.documento.nativeElement.value = this.nroDocumento;
    }

  }

  searchClient(token, search) {
    this._cliente.search( token, search ).subscribe( data => {
      this.clientsFind = data;
      this.isClientFind = true;
    } );
  }

  seleccionar_cliente( cliente: Cliente ) {
    this.isClientFind = false;
    this.emit_cliente.emit( cliente );
  }


  escribirCliente( nombre, documento? ) {
    this.textoBuscado.nativeElement.value = nombre;
    this.documento.nativeElement.value = documento || '';
  }

  nombreCliente( cliente: Cliente ) {
    if ( (typeof cliente.nombre != 'undefined') && cliente.nombre != '') {
      return cliente.nombre + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno;
    }

    return cliente.razon_social;
  }

  searchByDNIRimac( documento: string ){
    this._int
    .personaObtener( documento )
    .subscribe( (persona: Cliente) => {
      this.seleccionar_cliente( persona );
      this.escribirCliente( this.nombreCliente( persona ), persona.nro_documento );
      this.emit_cliente.emit( persona );
    });
  }

}
