import { Injectable } from '@angular/core';
import { isEmpty } from 'rxjs/operators';
import { AseguradoraService } from '../aseguradora/aseguradora.service';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class CommonsService {

  constructor( public _aseguradora: AseguradoraService,
              public _usuario: UsuarioService ) { }

  toNumber( value: string, defValue: number ): number {

    if ( value && Number( value ) !== Number.NaN ) {
      return Number( value );
    }

    return defValue;
  }

  isEmpty( value ) {
    return (typeof value == 'undefined' || value==null || value=='');
  }

  assertNotEmpty( value, message ) {
    if ( this.isEmpty( value ) ) {
      throw ( new Error( this.isEmpty(message) ? 'Error empty value' : message) );
    }
  }

  isEmptyValues( ...values: any[] ): boolean {
    let isEmpty = false;
    values.forEach( ( value: any ) => {
      if ( this.isEmpty( value ) ) {
        isEmpty = true;
        return;
      }
    });

    return isEmpty;
  }

  fechaToStr( fecha ) {
    if( typeof fecha === 'undefined' ){
      return '-';
    }
    return new Intl.NumberFormat('es', { minimumIntegerDigits: 2 }).format( fecha.day ) + ' ' + this.getMonth(fecha.month)  + ' ' + Math.round( fecha.year % 100 );
  }

  fechaToISO_ES( fecha ) {

    if( typeof fecha === 'undefined' ){
      return '';
    }
    
    return fecha.day + '/' + fecha.month + '/' + fecha.year ;
  }

  getMonth( month: number ){
    const months = [ '', 'ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'set', 'oct', 'nov', 'dic' ];
    return months[ month ];
  }

  static toDate( fecha: string ): Date {
    return new Date( fecha );
  }
}
