import { Component, OnInit, Input } from '@angular/core';
import { Producto } from 'src/app/models/producto.model';

import swal from 'sweetalert';
import { CommonsService } from 'src/app/services/service.index';

@Component({
  selector: 'app-tbl-producto',
  templateUrl: './tbl-producto.component.html',
  styles: []
})
export class TblProductoComponent implements OnInit {

  @Input() data: Array<Producto> = [];

  constructor( public _commons: CommonsService ) { }

  ngOnInit() { }

  beforeDelete(index: number): void {

    let mensaje = '¿Desea eliminar el registro seleccionado?';

    if ( !this._commons.isEmpty(this.data[index].nro_poliza) ) {
      mensaje = '¿Desea eliminar el registro con póliza ' + this.data[index].nro_poliza + '?';
    }

    swal( mensaje , {
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Cancelar',
            visible: true
          },
          catch: {
            text: 'Eliminar',
            value: true
          }
        }
      }).then( (value) => {
        if ( value ) {
          this.deleteArchivo( index );
        }
    });
  }

  deleteArchivo(index: number): void {
    this.data.splice(index, 1 );
  }

}
