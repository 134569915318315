import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Slim } from 'src/app/models/slim.model';
import { environment as env } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoberturaService {

  readonly endpoint = env.endpoint;

  constructor( protected httpClient: HttpClient ) { }

  coberturaList( token: String ) {
    let url = this.endpoint + '/cobertura/?token=' + token;
    return this.httpClient.get( url ).pipe( map( (_: any) => _.data ) );
  }

  list( tipoCobertura, aseg, riesgo, uso, tasa ) {
    let url = this.endpoint.concat('/slim/', aseg, '/', riesgo, '/', tipoCobertura, '/', uso, '/', tasa);
    return this.httpClient.get( url ).pipe( map( (_: any) => _.data ) );
  }

  slimList( token: String, aseguradora: String, riesgo: String, tipo_slip: String, uso: String, tasa: String ) {
    let url = this.endpoint + '/slim/' + aseguradora + '/' + riesgo + '/' + tipo_slip + '/' + uso + '/' + tasa + '?token=' + token;
    return this.httpClient.get( url ).pipe( map( (_: any) => _.data ) );
  }

  saveSlim( slim: Slim ) {
    let url = this.endpoint + '/slim/';
    return this.httpClient.post( url, slim ).pipe( map( (_: any) => _.data ) );
  }

  updateSlim( slim: Slim ) {
    let url = this.endpoint + '/slim/';
    return this.httpClient.put( url, slim ).pipe( map( (_: any) => _.data ) );
  }
}
