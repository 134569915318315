import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModeloService, UsuarioService } from 'src/app/services/service.index';
import { Modelo } from 'src/app/models/modelo.model';

@Component({
  selector: 'app-frm-consulta-modelo',
  templateUrl: './frm-consulta-modelo.component.html',
  styles: []
})
export class FrmConsultaModeloComponent implements OnInit {

  modelosFind: Array<Modelo> = [];
  isModeloFind: boolean = false;

  @Output() modeloSelect: EventEmitter<Modelo> = new EventEmitter();

  constructor( public _modelo: ModeloService,
                public _usuario: UsuarioService ) { }

  ngOnInit() {
  }

  searchModelo(token, search) {
    this._modelo.search( token, search ).subscribe( data => {
      this.modelosFind = data;
      this.isModeloFind = true;
    } );
  }

  outModelo( modelo: Modelo ) {
    this.modeloSelect.emit( modelo );
  }

}
