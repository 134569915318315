import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment as env } from './../../../environments/environment';
import { map } from 'rxjs/operators';
import { Usuario } from 'src/app/models/usuario.model';
import swal from 'sweetalert';
import { environment as e } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  readonly endpoint = env.endpoint;
  usuario: Usuario = null;
  token: string = null;

  constructor( public http: HttpClient,
    public router: Router) {
    this.loadUserFromLocalStorage();
  }

  listAll() {
    let url = this.endpoint + '/usuario?token=' + this.token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  autenticar( nickname: string, password: string ) {
    let url = this.endpoint + '/login';
    return this.http.post( url, this.toJsonLogin(nickname, password) ).pipe(
      map( (resp: any) => {
        let usuario = resp.data;
        this.saveUserLocalStorage( resp.token, usuario );
        this.loadData();
        swal(resp.data.nombre, 'Bienvenido', 'success');
      })
    );
  }

  loadUserFromLocalStorage() {
    if ( localStorage.getItem('token') ) {
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
      this.token = localStorage.getItem('token');
    }
  }

  saveUserLocalStorage(token: string, usuario: Usuario) {
    this.usuario = usuario;
    this.token = token;
    localStorage.setItem( "usuario", JSON.stringify(usuario) );
    localStorage.setItem( "token", token );
  }

  saveUser( usuario: Usuario ){

    let url = this.endpoint + '/usuario?token=' + this.token;
    return this.http.post( url, usuario ).pipe( map( ( _: any) => _.data ) );

  }

  udpdateUser( usuario: Usuario ){

    let url = this.endpoint + '/usuario/' + usuario._id + '?token=' + this.token;
    return this.http.put( url, usuario ).pipe( map( ( _: any) => _.data ) );

  }

  changePasswordUse( usuario: Usuario ){

    let url = this.endpoint + '/usuario/password/' + usuario._id + '?token=' + this.token;
    return this.http.put( url, usuario ).pipe( map( ( _: any) => _.data ) );

  }

  loadData(){

    let url = this.endpoint + '/parametro/';
    this.http
    .get( url )
    .pipe( map( (xhrData: any) => xhrData.data ) )
    .subscribe( xhrPar => localStorage.setItem( e.VAR_PARAMETRO, JSON.stringify( xhrPar ) ) );

  }

  isAutenticated() {
    this.loadUserFromLocalStorage();
    return this.token !== null;
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem( e.VAR_PARAMETRO );

    this.usuario = null;
    this.token = null;

    this.router.navigate( ['/login'] );
  }

  deleteUser( idUser: string ){
    let url = this.endpoint + '/usuario/' + idUser + '?token=' + this.token;
    return this.http.delete( url ).pipe( map( ( _: any) => _.data ) );
  }

  findById( idUser: string ): Observable<Usuario> {
    let url = this.endpoint + '/usuario/' + idUser + '?token=' + this.token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  private toJsonLogin( nickname: string, password: string ) {
    return {
      correo: nickname,
      password: password
    };
  }
}
