import { Injectable } from "@angular/core";
import { forkJoin } from "rxjs";
import { Auto } from "src/app/models/auto.model";
import { Cliente } from "src/app/models/cliente.model";
import { AseguradoraService } from "../aseguradora/aseguradora.service";
import { AutoService } from "../auto/auto.service";
import { ClienteService } from "../cliente/cliente.service";
import { CoberturaService } from "../cobertura/cobertura.service";
import { GpsService } from "../modelo/gps.service";
import { ParametroService } from "../parametro/parametro.service";
import { RiesgoService } from "../riesgo/riesgo.service";
import { UsuarioService } from "../usuario/usuario.service";
import { CotizacionService } from "./cotizacion.service";

import { GRUPO_DEPARTAMENTO, GRUPO_PROVINCIA, GRUPO_DISTRITO, GRUPO_COBERTURA, GRUPO_COBERTURA_ADICIONAL, GRUPO_SERVICIOS_ASISTENCIAS, GRUPO_DEDUCIBLES } from 'src/app/config/constants';
import { Aseguradora } from "src/app/models/aseguradora.model";
import { take } from "rxjs/operators";
import { ReporteService } from "./reporte.service";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})
export class PrevCotizacionService {

    cssStruct: any = {};
    cotizacion: any = {};
    auto: Auto = new Auto();
    cliente: Cliente = new Cliente();
    aseguradoras: Array<Aseguradora> = [];
    primasCotizacion = [];
    coberturaData = [];
    coberturaAdicionalData = [];
    serviciosAsistenciaData = [];
    deduciblesData = [];

    constructor(
        public _usuario: UsuarioService,
        public _cliente: ClienteService,
        public _auto: AutoService,
        public _parametro: ParametroService,
        public _cotizacion: CotizacionService,
        public _aseguradora: AseguradoraService,
        public _cobertura: CoberturaService,
        public _gps: GpsService,
        public _riesgo: RiesgoService,
        private _reporte: ReporteService) { }


    loadCotizacionData(idCotizacion: string) {

        let loadCotPromise = new Promise((res, rej) => {
            this._cotizacion.getById(this._usuario.token, idCotizacion).subscribe(_c => {

                this.cotizacion = _c;
                this.generateCss();

                forkJoin(
                    this._auto.getById(this._usuario.token, this.cotizacion.auto._id),
                    this._cliente.getById(this._usuario.token, this.cotizacion.cliente._id)
                ).subscribe(([auto, cliente]) => {
                    this.auto = auto;
                    this.cliente = cliente;
                    this.buscarParametros()
                        .then(xhr => res());
                });

            });
        });

        return loadCotPromise;

    }

    generateCss() {

        this.cssStruct.colCias = 6;
        this.cssStruct.colCoberturas = 6;

        if (this.cotizacion.primas.length === 5) {
            this.cssStruct.colCias = 2;
            this.cssStruct.colCoberturas = 2;
        }

        if (this.cotizacion.primas.length === 4) {
            this.cssStruct.colCias = 2;
            this.cssStruct.colCoberturas = 4;
        }

        if (this.cotizacion.primas.length === 3) {
            this.cssStruct.colCias = 3;
            this.cssStruct.colCoberturas = 3;
        }

        if (this.cotizacion.primas.length === 2) {
            this.cssStruct.colCias = 4;
            this.cssStruct.colCoberturas = 4;
        }
    }

    async buscarParametros() {

        forkJoin(
            this._parametro.listByGroupStartName(GRUPO_DEPARTAMENTO, this.cliente.departamento),
            this._parametro.listByGroupStartName(GRUPO_PROVINCIA, this.cliente.provincia),
            this._parametro.listByGroupStartName(GRUPO_DISTRITO, this.cliente.distrito)
        ).subscribe(([dep, prov, dist]) => {
            this.cliente.departamento = dep[0].alias;
            this.cliente.provincia = prov[0].alias;
            this.cliente.distrito = dist[0].alias;
        });


        let indice = 0;
        let totalPrimas = this.cotizacion.primas.length;

        this.aseguradoras = [];
        this.coberturaData = [];
        this.coberturaAdicionalData = [];
        this.serviciosAsistenciaData = [];
        this.deduciblesData = [];

        // Obteniendo las cias
        for (let e of this.cotizacion.primas) {

            this.primasCotizacion.push(e.prima_total);

            forkJoin(
                this._aseguradora.getById(this._usuario.token, e.cia),
                this._cobertura.slimList(this._usuario.token, e.cia, e.riesgo, GRUPO_COBERTURA, this.cotizacion.uso, this.cotizacion.tasa),
                this._cobertura.slimList(this._usuario.token, e.cia, e.riesgo, GRUPO_COBERTURA_ADICIONAL, this.cotizacion.uso, this.cotizacion.tasa),
                this._cobertura.slimList(this._usuario.token, e.cia, e.riesgo, GRUPO_SERVICIOS_ASISTENCIAS, this.cotizacion.uso, this.cotizacion.tasa),
                this._cobertura.slimList(this._usuario.token, e.cia, e.riesgo, GRUPO_DEDUCIBLES, this.cotizacion.uso, this.cotizacion.tasa))
                .subscribe((arrdata: Array<any>) => {
                    this.aseguradoras.push(arrdata[0]);
                    this.coberturaData.push(arrdata[1]);
                    this.coberturaAdicionalData.push(arrdata[2]);
                    this.serviciosAsistenciaData.push(arrdata[3]);
                    this.deduciblesData.push(arrdata[4]);

                    if ((++indice) == totalPrimas) {
                        return Promise.resolve();
                    }

                });

        }

    }

    generarPdf() {

        let docDefinition = this._reporte.pdfGenerador(this.nombreCliente(this.cliente),
            this.cliente,
            this.auto,
            this.cotizacion,
            this.aseguradoras,
            this.coberturaData,
            this.coberturaAdicionalData,
            this.deduciblesData,
            this._usuario.usuario );

        let win = window.open('', '_blank');
        pdfMake.createPdf(docDefinition).open({}, win);
    }

    nombreCliente(cliente: any) {
        if ((typeof cliente.nombre != 'undefined') && cliente.nombre != '') {
            return cliente.nombre + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno;
        }

        return cliente.razon_social;
    }
}