import { Component, OnInit } from '@angular/core';
import { ParametroService, AseguradoraService, RiesgoService, UsuarioService } from 'src/app/services/service.index';
import { Parametro } from 'src/app/models/parametro.model';
import { GRUPO_TASA } from '../../config/constants';
import { Aseguradora } from 'src/app/models/aseguradora.model';
import { Riesgo } from '../../models/riesgo.model';
import { Tasa } from '../../models/tasa.model';

@Component({
  selector: 'app-tasa',
  templateUrl: './tasa.component.html'
})
export class TasaComponent implements OnInit {

  tasas: Array<Parametro> = [];
  aseguradoras: Array<Aseguradora> = [];
  riesgos: Array<Riesgo> = [];

  tasasListadas: Array<Tasa> = [];
  planes: Array<any> = [];

  tasaSelected: String = '';
  ciaSelected: String = '';
  riesgoSelected: String = '';

  constructor(
    private _usuario: UsuarioService,
    private _parametro: ParametroService,
    private _aseguradora: AseguradoraService,
    private _riesgo: RiesgoService ) {

    _parametro
    .listGroupChildren( GRUPO_TASA )
    .subscribe( xhr =>  this.tasas = xhr );

    _aseguradora
    .listAll( _usuario.token )
    .subscribe( xhr => this.aseguradoras = xhr );

  }

  loadRiesgos( cia: String ) {

    this._riesgo
    .listByCia( cia )
    .subscribe( xhr => this.riesgos = xhr );

    this.planes = this.aseguradoras.filter( (as) => as._id == cia )[0].planes;

  }

  consultar() {
    this._riesgo
    .listByTasaAsegRiesgo( this.tasaSelected, this.ciaSelected, this.riesgoSelected )
    .subscribe( xhr => this.tasasListadas = xhr );
  }

  editarTasa( tasa ) {

    this._riesgo
    .updateTasa( tasa )
    .subscribe( xhr => console.log( xhr ) );

  }

  ngOnInit() { }

}
