import { Component, OnInit } from '@angular/core';
import { ModeloService, RiesgoService } from 'src/app/services/service.index';
import { Riesgo } from '../../models/riesgo.model';

import swal from 'sweetalert';

@Component({
  selector: 'app-riesgo',
  templateUrl: './riesgo.component.html'
})
export class RiesgoComponent implements OnInit {

  modelos: Array<any> = [];
  riesgos: Array<Riesgo> = [];

  riesgo_struct: any = {};

  constructor( private _modelo: ModeloService,
    private _riesgo: RiesgoService ) {
      _riesgo
      .list()
      .subscribe( ( xhr: Array<Riesgo> ) => {
        for ( let r of xhr ) {
          if ( typeof this.riesgo_struct[ r.aseguradora ] == 'undefined' ){
            this.riesgo_struct[ r.aseguradora ] = [];
          }
          this.riesgo_struct[ r.aseguradora ].push( r );
        }
      });
  }

  ngOnInit() {
  }

  listModelos( selector: string ) {
    this._modelo
    .modeloRiesgoView( selector )
    .subscribe( xhr => this.modelos = xhr );
  }

  editar( index: number ) {

    let modeloView = this.modelos[ index ];

    let modeloRiesgo = {
      _id: modeloView._id,
      hasta_anio: modeloView.hasta_anio,
      modelo: modeloView.modelo._id,
      aseguradora: modeloView.aseguradora._id,
      riesgo: modeloView.riesgo._id
    };

    this._modelo
    .editarModeloRiesgo( modeloRiesgo )
    .subscribe( xhr => console.log( xhr ) );
  }

  eliminar( index: number ) {
    swal('¿Desea eliminar el registro ' + ( this.modelos[index].marca + ' ' + this.modelos[index].nombre ) + '?' , {
      closeOnClickOutside: false,
      buttons: {
        cancel: {
          text: 'Cancelar',
          visible: true
        },
        catch: {
          text: 'Eliminar',
          value: true
        }
      }
    }).then( (value) => {
      if ( value ) {
        this._modelo
        .eliminarModeloRiesgo( this.modelos[index]._id )
        .subscribe( xhr => this.modelos.splice( index, 1 ) );
      }
    });
  }

}
