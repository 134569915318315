import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/service.index';
import { UsuarioService } from '../../services/service.index';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {

  constructor( public _sidebar: SidebarService,
    public _usuario: UsuarioService ) { }

  ngOnInit() {
  }

  hasPermission( codigo: string, opciones: [string] ): boolean{

    

    let hasPermission = false;
    opciones.forEach( (value: string) => {
      if( value == codigo ){
        hasPermission = true;
      }
    });

    console.log( "Buscando codigo: ", codigo, opciones, hasPermission, hasPermission || (codigo == 'ALL') );

    return hasPermission || (codigo == 'ALL');

  }

}
