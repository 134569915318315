import { Injectable } from '@angular/core';
import { environment as env } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AutoService {
  
  readonly endpoint = env.endpoint;

  constructor( public http: HttpClient ) { }

  getById( token: String, id: String ) {
    let url = this.endpoint + '/auto/' + id + '?token=' + token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }
}
