import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment as env } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RamoTecnicoService {

  readonly endpoint = env.endpoint;
  constructor( public http: HttpClient ) { }

  listbyAseguradora(idAseguradora: string) {
    let url = this.endpoint + '/v_ramo/aseg/' + idAseguradora;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }
}
