import { Component, OnDestroy, OnInit } from '@angular/core';
import { CotizacionService, UsuarioService } from 'src/app/services/service.index';
import { DialogService } from 'src/app/services/commons/dialog.service';
import { take } from 'rxjs/operators';
import { DialogEnum } from 'src/app/services/commons/dialog.enum';
import { Cotizacion } from 'src/app/models/cotizacion.model';
import { PrevCotizacionService } from 'src/app/services/cotizacion/prev_cotizacion.service';



declare function init_plugins();

@Component({
  selector: 'app-cotizacion',
  templateUrl: './cotizacion.component.html',
  styleUrls: ['./cotizacion.component.css']
})
export class CotizacionComponent implements OnInit, OnDestroy {

  public isLoading = true;
  public cotizaciones: Array<Cotizacion> = [];

  constructor( 
    public _cotizacion: CotizacionService,
    public _usuario: UsuarioService,
    private _dialog: DialogService,
    private _prevCotizacion: PrevCotizacionService ) {
    this.init();
  }

  ngOnInit() {
    init_plugins();
  }

  ngOnDestroy(){
    
  }

  init() {
    this.listAll();
  }

  listAll() {
    this._cotizacion.listAll( this._usuario.token ).subscribe( data => { this.cotizaciones = data; this.isLoading = false; } );
  }

  nombreCliente( ctz: any ) {
    if ( (typeof ctz.cliente.nombre !== 'undefined') && ctz.cliente.nombre !== '') {
      return ctz.cliente.nombre + ' ' + ctz.cliente.apellido_paterno + ' ' + ctz.cliente.apellido_materno;
    }

    return ctz.cliente.razon_social;
  }

  eliminarCotizacion( index ) {

    const mensaje = '¿Desea eliminar la cotización ' + this.cotizaciones[index].codigo + '?';

    this._dialog
    .confirm( mensaje, 'Eliminar' )
    .then( (value: Boolean) => {
      if ( value ) {
        this._cotizacion
        .eliminar( this.cotizaciones[index]._id )
        .pipe( take(1) )
        .subscribe( _data => this.cotizaciones.splice( index, 1 ) );
      }
    });

  }

  generarCertificado( index: number ){
    
    const mensaje = '¿Desea generar el certificado para la cotización ' + this.cotizaciones[index].codigo + '?';

    this._dialog
    .confirm( mensaje, 'Aceptar' )
    .then( (value: Boolean) => {
      if ( value ) {
        this._cotizacion
        .eliminar( this.cotizaciones[index]._id )
        .pipe( take(1) )
        .subscribe( _data => this.cotizaciones.splice( index, 1 ) );
      }
    });
  }

}
