import { environment as env } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Aseguradora } from 'src/app/models/aseguradora.model';
import { UsoAseguradora } from 'src/app/models/uso_aseguradora.model';

@Injectable({
  providedIn: 'root'
})
export class AseguradoraService {

  readonly endpoint = env.endpoint;

  constructor( public http: HttpClient ) { }

  getById( token: String, id: String ) {
    let url = `${this.endpoint}/aseguradora/${id}?token=${token}`;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  listAll( token: String ) {
    let url = this.endpoint + '/aseguradora/all?token=' + token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  listUsos( token: String, id: String ) {
    let url = this.endpoint + '/uso_aseg/aseg/' + id + '?token=' + token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  findInList( idAseg: String, asegList: Array<Aseguradora> ) {
    for ( let asg of asegList ) {
      if ( asg._id === idAseg ) {
        return asg;
      }
    }
    return null;
  }

  findRamosInList( idRamo: String, ramos: Array<UsoAseguradora> ) {
    for ( let ramo of ramos ) {
      if ( ramo.valor === idRamo ) {
        return ramo;
      }
    }
    return null;
  }
}
