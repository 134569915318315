import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SidebarService {
  menu: any[] = [
    {
      titulo: "Inicio",
      icono: "mdi mdi-home-outline",
      url: "/dashboard",
      codigo: "ALL"
    },
    {
      titulo: "Gestión",
      icono: "mdi mdi-gauge",
      codigo: "ALL",
      submenu: [
        { titulo: "Cotizaciones", url: "/cotizacion", codigo: "ALL" },
        { titulo: "Clientes", url: "/cliente", codigo: "CLIENTES" }
      ]
    },
    {
      titulo: "Configuraciones",
      icono: "mdi mdi-settings",
      codigo: "ALL",
      submenu: [
        {
          titulo: "Empresa / Agencias",
          url: "/agencias",
          codigo: "EMPRESA"
        },
        { titulo: "Perfiles", url: "/perfiles", codigo: "PERFILES" },
        { titulo: "Usuarios", url: "/usuario", codigo: "USUARIOS" },
        {
          titulo: "Emp. aseguradoras",
          url: "/cotizacion",
          codigo: "EMP_ASEGURADORAS"
        },
        { titulo: "Modelos", url: "/modelo", codigo: "MODELOS" },
        { titulo: "Tasas", url: "/tasa", codigo: "TASAS" },
        { titulo: "Riesgos", url: "/riesgo", codigo: "RIESGOS" },
        {
          titulo: "Coberturas y deducibles",
          url: "/coberturas",
          codigo: "COB_DEDUCIBLES"
        },
        {
          titulo: "Información maestra",
          url: "/maestros",
          codigo: "INF_MAESTRA"
        }
      ]
    }
  ];

  constructor() {}
}
