import { Perfil } from "./perfil.model";

export class Usuario {
    
    public nombre: string;
        public correo: string;
        public password: string;
        public role: string;
        public avatar: string;
        public cargo: string;
        public celular: string;
        public correo_firma: string;
        public _id: string;
        public planes_cot: Array<string>;
        public perfil: Perfil;
        public estado: number;

    public constructor(
    ) {
        this.perfil = { _id: '' };
     }
}
