import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment as env } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GpsService {
  
  readonly endpoint = env.endpoint;

  constructor( public http: HttpClient ) { }

  listByAseguradoraAndModel( aseguradora, modelo ) {
    let url = this.endpoint + '/gps/' + aseguradora + '/' + modelo;
    return this.http.get( url ).pipe( map( ( _: any ) => _.data ) );
  }

}
