import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Perfil } from 'src/app/models/perfil.model';
import { DialogService } from 'src/app/services/commons/dialog.service';
import { PerfilService } from 'src/app/services/usuario/perfil.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  opcionesSistema = env.OPCIONES_SISTEMA;

  perfiles: Array<Perfil> = [];

  constructor(
    private $perfil: PerfilService,
    private $dlg: DialogService
  ) { }

  ngOnInit() {

    this.$perfil
    .listAll()
    .pipe( first() )
    .subscribe( (xhrData: any) => this.perfiles = xhrData );

  }

  eliminarPerfil( indice: number ){

    this.$dlg
    .confirm( `¿Desea eliminar el perfil ${ this.perfiles[indice].nombre }?`, 'Eliminar' )
    .then( ( resp: boolean ) => {
      if( resp ) {
        let perfilPaso = this.perfiles[ indice ];
        perfilPaso.estado = 0;

        this.$perfil
        .update( perfilPaso )
        .pipe( first() )
        .subscribe( (xhrData) => {
          this.perfiles.splice( indice, 1 );
        });
      }
    })

  }

  opcionesToStr( opciones: string[] ){

    let opcionesStr = '';

    opciones.forEach( (value: string) => {
      opcionesStr = opcionesStr.concat( ', ', this.opcionesSistema[ value ] )
    });

    if( opcionesStr.length > 0 ){
      opcionesStr = opcionesStr.substr( 2 );
    }

    return opcionesStr;
  }

}
