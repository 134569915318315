import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Parametro } from 'src/app/models/parametro.model';
import { environment as e } from 'src/environments/environment';
import { environment as env } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParametroService {

  readonly endpoint = env.endpoint;

  constructor( public http: HttpClient ) { }

  listGroupChildren( selector: string ) {
    let url = this.endpoint + '/parametro/grupo/' + selector;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  eliminarParametro( id: string ) {
    let url = this.endpoint + '/parametro/' + id;
    return this.http.delete( url ).pipe( map( ( _: any) => _.data ) );
  }

  addParametro( parametro: Parametro ) {
    let url = this.endpoint + '/parametro/';
    return this.http.post( url, parametro ).pipe( map( ( _: any) => _.data ) );
  }

  updateParametro( id: string, value: string ) {
    let url = this.endpoint + '/parametro/update/' + id + '/' + value;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  listGroupChilderWithOrden( selector: string, order: string, ascDesc: number ) {
    let url = this.endpoint.concat('/parametro/grupo/', selector, '/', order, '/', ascDesc + '' );
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  listByGroupStartName( grupo: string, nombre: string ) {
    let url = this.endpoint + '/parametro/sw/' + grupo + '/' + nombre;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  findNameInList( name: String, parametroList: Array<Parametro> ) {
    for ( let prmt of parametroList ) {
      if ( prmt.nombre === name ) {
        return prmt;
      }
    }
    return null;
  }

  static findParametros( grupo: string ): Array<Parametro> {
    let parametros: Array<Parametro> = JSON.parse( localStorage.getItem( e.VAR_PARAMETRO ) );
    console.log('Parametros registrados');
    
    return parametros[ grupo ];

  }


  static findParametro( grupo: string, nombre: string ): Parametro {

    console.log( 'fin parametros' );

    let parametros = this.findParametros( grupo );
    let parametro: Parametro = null;

    for( const p of parametros ){
      if( p.nombre === nombre ){
        parametro = p;
        break;
      }
    }

    return parametro;
  }

}
