import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Usuario } from 'src/app/models/usuario.model';
import swal from 'sweetalert';
import { environment as e } from 'src/environments/environment';
import { Perfil } from 'src/app/models/perfil.model';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  readonly endpoint = e.endpoint;
  usuario: Usuario = null;
  token: string = null;

  constructor( public http: HttpClient,
    public router: Router) {
    this.loadUserFromLocalStorage();
  }

  listAll() {
    let url = this.endpoint + '/perfil?token=' + this.token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  save( perfil: Perfil ) {
    let url = this.endpoint + '/perfil?token=' + this.token;
    return this.http.post( url, perfil ).pipe( map( ( _: any) => _.data ) );
  }

  update( perfil: Perfil ) {
    let url = this.endpoint + '/perfil?token=' + this.token;
    return this.http.put( url, perfil ).pipe( map( ( _: any) => _.data ) );
  }

  listPerfilById( id: string ) {
    let url = this.endpoint + '/perfil/' + id + '?token=' + this.token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  loadUserFromLocalStorage() {
    if ( localStorage.getItem('token') ) {
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
      this.token = localStorage.getItem('token');
    }
  }

  
}
