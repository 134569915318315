import { Component, OnInit } from '@angular/core';
import { Parametro } from 'src/app/models/parametro.model';
import { ParametroService } from 'src/app/services/service.index';

import { GRUPO_VARIABLES_GLOBALES, ACTIVO } from 'src/app/config/constants';
import { take } from 'rxjs/operators';
import { DialogService } from 'src/app/services/commons/dialog.service';

@Component({
  selector: 'app-maestros',
  templateUrl: './maestros.component.html',
  styles: []
})
export class MaestrosComponent implements OnInit {

  pGlobales: Array<Parametro> = [];
  vGlobales: Array<Parametro> = [];

  constructor(
    private _param: ParametroService,
    private _dialog: DialogService
  ) {
    this.init();
   }

  ngOnInit() {
  }

  init() {

    this._param
    .listGroupChildren( GRUPO_VARIABLES_GLOBALES )
    .pipe( take(1) )
    .subscribe( xhrGlobales => {
      this.pGlobales = xhrGlobales;
      this.searchParametros( this.pGlobales[0].nombre );
    } );

  }

  searchParametros( grupo: string ) {
    this._param
    .listGroupChildren( grupo )
    .pipe( take(1) )
    .subscribe( xhrGlobales => this.vGlobales = xhrGlobales );
  }


  editarParametro(id: string, value: string) {

        this._param
        .updateParametro( id, value )
        .pipe( take(1) )
        .subscribe( _data => console.log( 'Actualizado' ) );

  }

  eliminarParametro( id: string, i: number ) {
    this._dialog
    .confirm( 'Desea eliminar la variable ' + this.vGlobales[ i ].alias + '?', 'Eliminar' )
    .then( (value: Boolean) => {
      if ( value ) {
        this._param
        .eliminarParametro( id )
        .pipe( take(1) )
        .subscribe( _data => console.log( 'Done!' ) );

        this.vGlobales.splice( i, 1 );
      }
    });
  }

  agregarParametro( grupo: string ) {

    const param: Parametro = {
      grupo: grupo,
      alias: ' ',
      nombre: grupo.concat( '-', new Date().getTime() + '' ),
      orden: new Date().getTime(),
      estado: ACTIVO
    };

    this._param
    .addParametro( param )
    .subscribe( xhrParam => this.vGlobales.push( xhrParam ));

  }

}
