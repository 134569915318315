import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { environment as env } from './../../../environments/environment';
import { Cotizacion } from 'src/app/models/cotizacion.model';
import { ReporteService } from './reporte.service';
import { RimacReqCotizacion } from 'src/app/models/rimac_integracion/request/rimac_req_cotizacion.model';
import { RimacRespCotizacion } from 'src/app/models/rimac_integracion/response/rimac_resp_cotizacion.model';


@Injectable({
  providedIn: 'root'
})
export class CotizacionService {

  readonly endpoint = env.endpoint;

  constructor(
    public http: HttpClient,
    public _rep: ReporteService
    ) { }

  obtenerCotizacionRimac( cot: RimacReqCotizacion ) {

    let url = this.endpoint.concat( '/int/rimac/' , env.int_rimac_entries.entry_5 );
    return this.http
    .post<RimacRespCotizacion>( url, cot )
    .pipe( take(1) );

  }

  listAll( token: String ) {
    let url = this.endpoint + '/cotizacion?token=' + token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  getById( token: String, id: String ) {
    let url = this.endpoint + '/cotizacion/' + id + '?token=' + token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  save( token: String, cotizacion: Cotizacion ) {
    let url = this.endpoint + '/cotizacion/?token=' + token;
    return this.http.post( url, cotizacion ).pipe( map( ( _: any) => _.data ) );
  }

  eliminar( id: String ) {
    let url = this.endpoint + '/cotizacion/del/' + id;
    return this.http.get( url ).pipe( map( ( _: any ) => _.data ) );
  }

  updatePrima( _id: string, tot_descuento: number, tot_incremento: number ){
    let url = this.endpoint + '/cotizacion/prima/update/' + _id;
    return this.http.post( url, { tot_descuento, tot_incremento } ).pipe( map( ( _: any ) => _.data ) );
  }
}
