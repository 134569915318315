import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modelo-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class MFormularioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
