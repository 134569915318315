export { RiesgoService } from './riesgo/riesgo.service';
export { RamoTecnicoService } from './views/ramo-tecnico.service';
export { PolizaService } from './poliza/poliza.service';
export { GpsService } from './modelo/gps.service';
export { AseguradoraService } from './aseguradora/aseguradora.service';
export { CommonsService } from './commons/commons.service';
export { CoberturaService } from './cobertura/cobertura.service';
export { AutoService } from './auto/auto.service';
export { ParametroService } from './parametro/parametro.service';
export { ClienteService } from './cliente/cliente.service';
export { CotizacionService } from './cotizacion/cotizacion.service';
export { ModeloService } from './modelo/modelo.service';
export { LoginGuard } from './guard/login.guard';
export { SettingsService } from './settings/settings.service';
export { SharedService } from './shared/shared.service';
export { SidebarService } from './shared/sidebar.service';
export { UsuarioService } from './usuario/usuario.service';
