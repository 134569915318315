import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cliente } from 'src/app/models/cliente.model';
import { map, mergeMap } from 'rxjs/operators';
import { CommonsService } from '../commons/commons.service';
import { TipoContacto } from 'src/app/models/tipo_contacto.model';
import { ParametroService } from '../parametro/parametro.service';
import { environment as e } from 'src/environments/environment';
import { environment as env } from './../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class IntegracionService {

    readonly endpoint = env.endpoint;

    constructor(public http: HttpClient) {}

    cotizar(
        plan: number,
        producto: number,
        placa: string,
        anioFabricacion: string,
        sumaAsegurada: number,
        modelo: any ) {

        let url = this.endpoint + '/int/cotizar';

        let data = {
            reqData: {
                plan: plan,
                producto: producto,
                placa: placa,
                auto: modelo,
                anioFabricacion: anioFabricacion,
                sumaAsegurada: sumaAsegurada
            },
        };

        return this.http.post(url, data);

    }

    personaObtener( documento: string ): Observable<Cliente>{

        let tipoDocumento = '2'; // DNI

        if( documento.length !== 8 ) {
            tipoDocumento = '1';
        }

        const url = this.endpoint.concat( '/int/persona/obtener' );
        let data = {
            reqData: {
                tipoDocumento: tipoDocumento,
                numeroDocumento: documento
            }
        }

        

        return this.http.post<any>( url, data ).pipe(
            map( 
                (xhrData: any) =>{

                    const data = xhrData.data.payload;

                    let rSocial = '';
                    let nombre = data.nombres;
                    let aPaterno = data.apellidoPaterno || '';
                    let departamento = data.distrito.substring(0, 2);
                    let provincia = data.distrito.substring(0, 4);

                    let parametro = ParametroService.findParametro( e.GRUPO_TIPO_DATO_CLIENTE, 'PERSONAL' );

                    let telefono: TipoContacto = {
                        tipo: 'PERSONAL',
                        valor: (data.telefono == undefined)? '': data.telefono,
                        parametro: parametro
                    };

                    let email: TipoContacto = {
                        tipo: 'PERSONAL',
                        valor: (data.correo == undefined)? '': data.correo,
                        parametro: parametro
                    }

                    if( data.tipoDocumento == '1' ){
                        rSocial = data.nombres;
                        nombre = '';
                        aPaterno = '';
                    }

                    let c = new Cliente(
                            null,
                            data.tipoDocumento,
                            data.numeroDocumento,
                            rSocial,
                            null,
                            nombre,
                            aPaterno,
                            data.apellidoMaterno,
                            data.tipoDocumento == "1",
                            CommonsService.toDate( data.fechaNacimiento ),
                            departamento,
                            provincia,
                            data.distrito,
                            data.nombreVia + ' ' + data.numeroVia,
                            new Date() + '',
                            1,
                            [ email ],
                            [ telefono ]
                        );
                    return c;
                })
        );

    }

    autoObtener(placa: string) {

        let url = this.endpoint + '/int/auto/obtener';

        let data = {
            reqData: {
                valor: placa,
            },
        };

        return this.http.post(url, data);

    }

    autoMasObtener(
        modeloCodigo: string,
        marcaCodigo: string,
        anioFabricacion: number ) {

        let url = this.endpoint + '/int/automas/obtener';

        let data = {
            reqData: {
                codigoModelo: modeloCodigo,
                codigoMarca: marcaCodigo,
                anhoFabricacion: anioFabricacion
            },
        };

        return this.http.post(url, data);

    }
}
