import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment as env } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModeloService {

  readonly endpoint = env.endpoint;

  constructor( public http: HttpClient ) { }

  listAll( token: String ) {
    let url = this.endpoint + '/modelo?token=' + token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  search( token: String, search: String ) {
    let url = this.endpoint + '/modelo/search/' + search + '?token=' + token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  searchIntegracion( token: String, search: String ) {
    
    let filtro = {
      reqData: {
        valor: search
      }
    }
    
    let url = this.endpoint + '/int/marca/modelo?token=' + token;
    return this.http.post( url, filtro ).pipe( map( ( _: any) => _.data ) );
  }

  find( token: String, field: String, value: any ) {
    let url = this.endpoint + '/modelo/find/' + field + '/' + value + '?token=' + token;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  modeloRiesgoView( selector: string ) {
    let url = this.endpoint + '/modeloriesgo/v/' + selector;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  eliminarModeloRiesgo( id: string ) {
    let url = this.endpoint + '/modeloriesgo/del/' + id;
    return this.http.get( url ).pipe( map( ( _: any) => _.data ) );
  }

  editarModeloRiesgo( modeloRiesgo: any ) {
    let url = this.endpoint + '/modeloriesgo/';
    return this.http.put( url, modeloRiesgo ).pipe( map( ( _: any) => _.data ) );
  }
}
