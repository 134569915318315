import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Perfil } from 'src/app/models/perfil.model';
import { DialogService } from 'src/app/services/commons/dialog.service';
import { PerfilService } from 'src/app/services/usuario/perfil.service';

@Component({
  selector: 'app-perfil-new',
  templateUrl: './perfil-new.component.html',
  styleUrls: ['./perfil-new.component.css']
})
export class PerfilNewComponent implements OnInit {

  perfil: Perfil = { nombre: '' };

  constructor(
    private $dlg: DialogService,
    private $perfil: PerfilService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  isSelected( opcion: string ) {

    let isSelected = false;
    const opciones = this.perfil.opciones;

    try{
      opciones.forEach( (value, index) => {
        if( value === opcion ){
          isSelected = true;
        }
      });
    }catch( e ){
      console.log(e);
    }

    return isSelected;

  }

  registrarPerfil(){

    this.$dlg
    .confirm('¿Desea registrar el perfil?', 'Aceptar' )
    .then( (resp) => {

      if(resp){
        this.$perfil
        .save( this.perfil )
        .pipe( first() )
        .subscribe( (xhrData: any) => {
          this.router.navigate([ '/perfiles' ]);
        });
      }

    })

  }

  addOptionIfNotSelect( opcionSelected: string ) {
    
    let opciones = this.perfil.opciones;
    let ifOptionSelected = false;

    if( ( typeof opciones != 'undefined') && (opciones != null) ) {
      opciones.forEach( (value: string) => {
        if( value == opcionSelected) {
          ifOptionSelected = true;
          return;
        }
      });
    } else {
      opciones = [];
    }


    if(!ifOptionSelected){
      opciones.push( opcionSelected );
      this.perfil.opciones = opciones;
    }
    console.log(this.perfil);
  }

  removeOption( optionSelected: string ){
    let opciones = this.perfil.opciones;
    opciones.forEach( (value: string, index: number) => {
      if( value == optionSelected) {
        this.perfil.opciones.splice( index, 1 );
        return;
      }
    });
  }

}
