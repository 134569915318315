// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endpoint: 'http://localhost:3200',
  int_rimac_entries: {
    entry_1: "ebe38c8632392e727f53ce4de37ae06b",
    entry_2: "ae878d544c7f4921590aac383885f7fb",
    entry_3: "6f4ec388acb5fa2b081bf13685150c8a",
    entry_4: "61dc855fbd062f91dcfe04af774dbfef",
    entry_5: "8bdb801c4610b50a77a7f9b4c35075a5",
  },
  rimac: "5c3cffcf9c575a317cbaf904",
  VAR_PARAMETRO: 'parametros',
  GRUPO_TIPO_DATO_CLIENTE: 'TIPO_DATO_CLIENTE',
  OPCIONES_SISTEMA: {
    CLIENTES: 'Clientes',
    PERFILES: 'Perfiles',
    USUARIOS: 'Usuarios',
    MODELOS: 'Modelos',
    TASAS: 'Tasas',
    RIESGOS: 'Riesgos',
    COB_DEDUCIBLES: 'Coberturas y deducibles',
    INF_MAESTRA: 'Información maestra'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
