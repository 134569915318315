import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { forkJoin, Subscription } from 'rxjs';
import { Auto } from 'src/app/models/auto.model';
import { Cliente } from 'src/app/models/cliente.model';
import {
    GRUPO_DEPARTAMENTO,
    GRUPO_PROVINCIA,
    GRUPO_DISTRITO,
    GRUPO_COBERTURA,
    GRUPO_COBERTURA_ADICIONAL,
    GRUPO_SERVICIOS_ASISTENCIAS,
    GRUPO_DEDUCIBLES,
} from 'src/app/config/constants';
import { Aseguradora } from '../../models/aseguradora.model';

import {
    CotizacionService,
    UsuarioService,
    ClienteService,
    AutoService,
    ParametroService,
    GpsService,
    AseguradoraService,
    CoberturaService,
    RiesgoService,
    CommonsService,
} from 'src/app/services/service.index';

import swal from 'sweetalert';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ReporteService } from 'src/app/services/cotizacion/reporte.service';
import { environment as env } from 'src/environments/environment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-prev-cotizacion',
    templateUrl: './prev-cotizacion.component.html',
    styleUrls: ['./cotizacion.component.css'],
})
export class PrevCotizacionComponent implements OnInit, OnDestroy {
    cotizacion: any = {};
    auto: Auto = new Auto();
    cliente: Cliente = new Cliente();
    aseguradoras: Array<Aseguradora> = [];

    coberturaData = [];
    coberturaAdicionalData = [];
    serviciosAsistenciaData = [];
    deduciblesData = [];

    descuentos = [];
    incrementos = [];
    primasCotizacion = [];
    descuentos_com = [];

    isLoading = true;

    cssStruct: any = {};

    primasBackup = [];

    subscribers: Subscription[] = [];

    constructor(
        public activatedRoute: ActivatedRoute,
        public _usuario: UsuarioService,
        public _cliente: ClienteService,
        public _auto: AutoService,
        public _parametro: ParametroService,
        public _cotizacion: CotizacionService,
        public _aseguradora: AseguradoraService,
        public _cobertura: CoberturaService,
        public _gps: GpsService,
        public _riesgo: RiesgoService,
        private _reporte: ReporteService,
        private _com: CommonsService
    ) {
        this.activatedRoute.params.subscribe((params) => {
            _cotizacion
                .getById(_usuario.token, params['id'])
                .subscribe((_c) => {
                    this.cotizacion = _c;

                    console.log(_c);

                    this.primasBackup = JSON.parse(
                        JSON.stringify(this.cotizacion.primas)
                    );
                    this.generateCss();

                    forkJoin(
                        _auto.getById(_usuario.token, this.cotizacion.auto._id),
                        _cliente.getById(
                            _usuario.token,
                            this.cotizacion.cliente._id
                        )
                    ).subscribe(([auto, cliente]) => {
                        this.auto = auto;
                        this.cliente = cliente;
                        this.buscarParametros();
                    });
                });
        });
    }

    ngOnInit() {}

    async buscarParametros() {
        let joins = forkJoin(
            this._parametro.listByGroupStartName(
                GRUPO_DEPARTAMENTO,
                this.cliente.departamento
            ),
            this._parametro.listByGroupStartName(
                GRUPO_PROVINCIA,
                this.cliente.provincia
            ),
            this._parametro.listByGroupStartName(
                GRUPO_DISTRITO,
                this.cliente.distrito
            )
        ).subscribe(([dep, prov, dist]) => {
            this.cliente.departamento = dep[0] ? dep[0].alias : '';
            this.cliente.provincia = prov[0] ? prov[0].alias : '';
            this.cliente.distrito = dist[0] ? dist[0].alias : '';

            this.isLoading = false;
        });

        this.subscribers.push(joins);

        // Obteniendo las cias
        for (let e of this.cotizacion.primas) {
            this.primasCotizacion.push(e.prima_total);

            let aseguradoraTemp = await this._aseguradora
                .getById(this._usuario.token, e.cia)
                .toPromise();

            this.aseguradoras.push(aseguradoraTemp);

            let coberturasParcial = await this._cobertura
                .slimList(
                    this._usuario.token,
                    e.cia,
                    e.riesgo,
                    GRUPO_COBERTURA,
                    this.cotizacion.uso,
                    e.plan
                )
                .toPromise();
            this.coberturaData.push(coberturasParcial);

            let coberturasAdicionalParcial = await this._cobertura
                .slimList(
                    this._usuario.token,
                    e.cia,
                    e.riesgo,
                    GRUPO_COBERTURA_ADICIONAL,
                    this.cotizacion.uso,
                    e.plan
                )
                .toPromise();
            this.coberturaAdicionalData.push(coberturasAdicionalParcial);

            let serviciosAdicionalParcial = await this._cobertura
                .slimList(
                    this._usuario.token,
                    e.cia,
                    e.riesgo,
                    GRUPO_SERVICIOS_ASISTENCIAS,
                    this.cotizacion.uso,
                    e.plan
                )
                .toPromise();
            this.serviciosAsistenciaData.push(serviciosAdicionalParcial);

            let deduciblesParcial = await this._cobertura
                .slimList(
                    this._usuario.token,
                    e.cia,
                    e.riesgo,
                    GRUPO_DEDUCIBLES,
                    this.cotizacion.uso,
                    e.plan
                )
                .toPromise();

            this.deduciblesData.push(deduciblesParcial);
        }

        // alert( JSON.stringify( this.aseguradoras ) );
    }

    generateCss() {
        this.cssStruct.colCias = 6;
        this.cssStruct.colCoberturas = 6;

        if (this.cotizacion.primas.length === 5) {
            this.cssStruct.colCias = 2;
            this.cssStruct.colCoberturas = 2;
        }

        if (this.cotizacion.primas.length === 4) {
            this.cssStruct.colCias = 2;
            this.cssStruct.colCoberturas = 4;
        }

        if (this.cotizacion.primas.length === 3) {
            this.cssStruct.colCias = 3;
            this.cssStruct.colCoberturas = 3;
        }

        if (this.cotizacion.primas.length === 2) {
            this.cssStruct.colCias = 4;
            this.cssStruct.colCoberturas = 4;
        }
    }

    calcular(i: number) {
        let subsRiesgo = this._riesgo
            .getById(this.cotizacion.primas[i].riesgo)
            .subscribe((riesgoDB) => {
                
                if ( this._com.toNumber(this.descuentos[i], 0) <= riesgoDB.desc_max_com ) {
                    
                    let descPNeta = (this.primasBackup[i].prima_neta * this._com.toNumber(this.descuentos[i], 0)) / 100;
                    let descPTotal = (this.primasBackup[i].prima_total * this._com.toNumber(this.descuentos[i], 0)) / 100;

                    let descComPNeta = (this.primasBackup[i].prima_neta * this._com.toNumber(this.descuentos_com[i], 0)) / 100;
                    let descComPTotal = (this.primasBackup[i].prima_total * this._com.toNumber(this.descuentos_com[i], 0)) / 100;

                    let incPNeta = (this.primasBackup[i].prima_neta * this._com.toNumber(this.incrementos[i], 0)) / 100;
                    let incPTotal = (this.primasBackup[i].prima_total * this._com.toNumber(this.incrementos[i], 0)) / 100;

                    this.cotizacion.primas[i].prima_neta = this.primasBackup[i].prima_neta + incPNeta - (descPNeta + descComPNeta);

                    this.cotizacion.primas[i].prima_total = this.primasBackup[i].prima_total + incPTotal - (descPTotal + descComPTotal);

                    if ( this.cotizacion.primas[i].prima_neta < this.obtenerPrimaMinima( this.aseguradoras[i], this.cotizacion.tasa ) ) {
                        this.cotizacion.primas[i].prima_neta =
                            this.obtenerPrimaMinima(
                                this.aseguradoras[i],
                                this.cotizacion.tasa
                            );
                        this.cotizacion.primas[i].prima_total =
                            this.cotizacion.primas[i].prima_neta *
                            this.aseguradoras[i].tasa;
                        return;
                    }

                    this.cotizacion.primas[i].tot_descuento = this.descuentos[i];
                    this.cotizacion.primas[i].tot_incremento = this.incrementos[i];

                    console.log('Prima a actualizar');
                    this._cotizacion.updatePrima(this.cotizacion.primas[i]._id, this.cotizacion.primas[i].tot_descuento, this.cotizacion.primas[i].tot_incremento)
                    .subscribe((primaXhr) => {
                        console.log(`Prima actualizada ${primaXhr}`);
                    });
                } else {
                    swal(
                        'Cotización debe contar con aprobación',
                        'Solo puede dar como máximo, el ' +
                            riesgoDB.desc_max_com +
                            '%',
                        'warning'
                    );
                }
            });
        this.subscribers.push(subsRiesgo);
    }

    obtenerPrimaMinima(aseguradora: Aseguradora, tasa: string) {
        console.log('Aseguradora ID', aseguradora._id);
        console.log('Rimac ID', env.rimac);
        console.log('Tasa', tasa);
        console.log(
            'Validacion',
            aseguradora._id === env.rimac && tasa === 'T002'
        );

        if (aseguradora._id === env.rimac && tasa === 'T002') {
            return 300;
        }

        return aseguradora.prima_minima;
    }

    obtener_descuento(i) {
        let tasa_descuento = this.cotizacion.primas[i].tasa;

        if (this.descuentos[i] != null) {
            tasa_descuento =
                (1 - this.descuentos[i] / 100) * this.cotizacion.primas[i].tasa;
        }

        let tasa_final = tasa_descuento;

        if (this.descuentos_com[i] != null) {
            tasa_final = tasa_descuento * (1 - this.descuentos_com[i] / 100);
        }

        return tasa_final;
    }

    enviar_email() {
        try {
            let docDefinition = this._reporte.pdfGenerador(
                this.nombreCliente(this.cliente),
                this.cliente,
                this.auto,
                this.cotizacion,
                this.aseguradoras,
                this.coberturaData,
                this.coberturaAdicionalData,
                this.deduciblesData,
                this._usuario.usuario
            );

            console.log(docDefinition);
            let win = window.open('', '_blank');
            pdfMake.createPdf(docDefinition).open({}, win);
        } catch (e) {
            console.log(e);
        }
    }

    nombreCliente(cliente: any) {
        if (typeof cliente.nombre != 'undefined' && cliente.nombre != '') {
            return (
                cliente.nombre +
                ' ' +
                cliente.apellido_paterno +
                ' ' +
                cliente.apellido_materno
            );
        }

        return cliente.razon_social;
    }

    toStrFecha(fecha: any) {
        return this._com.fechaToStr(fecha);
    }

    ngOnDestroy(): void {
        this.subscribers.forEach((sb) => sb.unsubscribe());
    }
}
