import { Injectable } from '@angular/core';

import swal from 'sweetalert';
import { DialogEnum } from './dialog.enum';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor() { }

  confirm( message: string, buttonMessage: string = 'Aceptar' ): Promise<boolean> {

    return swal( message , {
      closeOnClickOutside: false,
      title: 'Atención',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancelar',
          visible: true
        },
        catch: {
          text: buttonMessage,
          value: true
        }
      }
    });

  }

  alert( title: string = 'Atención', message: string, icon: DialogEnum ): Promise<boolean> {
    return swal( title, message, icon );
  }

}
