import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { Perfil } from 'src/app/models/perfil.model';
import { DialogEnum } from 'src/app/services/commons/dialog.enum';
import { DialogService } from 'src/app/services/commons/dialog.service';
import { UsuarioService } from 'src/app/services/service.index';
import { PerfilService } from 'src/app/services/usuario/perfil.service';
import { Usuario } from '../../models/usuario.model';

@Component({
  selector: 'app-usuario-new',
  templateUrl: './usuario-new.component.html',
  styleUrls: ['./usuario-new.component.css']
})
export class UsuarioNewComponent implements OnInit {

  isNew = false;
  disableForm = true;
  usuario: Usuario = new Usuario();
  perfiles: Array<Perfil> = [];

  constructor( 
    public $usuario: UsuarioService,
    private $perfil: PerfilService,
    private $dlg: DialogService,
    private activatedRouter: ActivatedRoute,
    private router: Router ) {
    this.init();
   }

  ngOnInit() { 
    this.init();
    this.listarPerfiles();
  }

  init() {
    this.activatedRouter.params.subscribe((params) => {
      
      if ( params['id'] == undefined ) {
        this.disableForm = false;
        this.isNew = true;

        console.log( this.usuario );
        return;
      }

      const idUsuario = params[ 'id' ];

      this.$usuario
      .findById( idUsuario )
      .pipe( first() )
      .subscribe( (xhrUser: Usuario) => {
        
        this.usuario = xhrUser;
        console.log( this.usuario );
        if( typeof this.usuario.perfil == 'undefined'){
          this.usuario.perfil = {};
        }

      });

    });

  }

  listarPerfiles(){
    this.$perfil
    .listAll()
    .subscribe( (xhrPerfiles: Array<Perfil>) => this.perfiles = xhrPerfiles );
  }

  guardarUsuario() {

    if( this.isNew ){
      this.$usuario
      .saveUser( this.usuario )
      .subscribe( ( xhrResp ) => {
        
        this.$dlg
        .alert( 'Atención', 'Usuario registrado con éxito', DialogEnum.SUCCESS )
        .then( (xhrData) => { this.router.navigate(['/usuario']) } );
      });

      return;
    }

    this.$usuario
      .udpdateUser( this.usuario )
      .subscribe( ( xhrResp ) => {
        this.$dlg
        .alert( 'Atención', 'Usuario editado con éxito', DialogEnum.SUCCESS )
        .then( (xhrData) => { this.router.navigate(['/usuario']) } );
      });

  }

  

  // eliminarUsuario( indice: number ) {

  //   this.$dlg
  //   .confirm( `¿Desea eliminar al usuario ${this.usuarios[ indice ].nombre }?`, 'Eliminar' )
  //   .then( (resp: boolean) => {
  //     if( resp ){
  //       this._usuario
  //       .deleteUser( this.usuarios[ indice ]._id )
  //       .subscribe( (xhrData: any) => {
  //         this.listar();
  //       });
  //     }
  //   });

  // }

}
