import { Fecha } from './fecha.model';
import { Parametro } from './parametro.model';
import { Aseguradora } from './aseguradora.model';
import { Modelo } from './modelo.model';
import { Archivo } from './archivo.model';

export class Producto {

    public ramoView: Parametro;
    public ciaView: Aseguradora;
    public modeloView: Modelo;

    public archivos: Array<Archivo>;

    public constructor(
        public cia?: string,
        public ramo?: string,

        /**
         * Vehicular
         */
        public modelo?: string,
        public anio?: number,
        public placa?: string,
        public nro_serie?: string,
        public nro_motor?: string,
        public gps_emp?: string,

        /**
         * Vida ley
         */
        public nro_pol_salud?: number,
        public tas_empleado?: number,
        public tas_obrero?: number,

        /**
         * SCTR
         */
        public tas_emp_salud?: number,
        public tas_obr_salud?: number,
        public pri_net_salud?: number,
        public nro_pol_pension?: number,
        public tas_emp_pension?: number,
        public tas_obr_pension?: number,
        public pri_net_pension?: number,

        /**
         * RAMO TECNICO
         */
        public rie_ram_tecnico?: string,
        public tas_ram_tecnico?: string,
        public pri_net_ram_tecnico?: string,
        public mon_obr_ram_tecnico?: string,
        public tip_obr_ram_tecnico?: string,
        public ubi_obr_ram_tecnico?: string,
        public dur_obr_ram_tecnico?: string,
        public sum_ase_ram_tecnico?: string,

        /**
         * ONCOLOGICO
         */
        public plan?: string,

        /**
         * Campos comunes
         */
        public endoso?: string,
        public desde?: Fecha,
        public hasta?: Fecha,
        public nro_poliza?: string,
        public prima_neta?: string,

        public _id?: string,
    ) { }
}
