import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Perfil } from 'src/app/models/perfil.model';
import { DialogService } from 'src/app/services/commons/dialog.service';
import { UsuarioService } from 'src/app/services/service.index';
import { PerfilService } from 'src/app/services/usuario/perfil.service';
import { Usuario } from '../../models/usuario.model';
import { environment as env } from 'src/environments/environment';
import { DialogEnum } from 'src/app/services/commons/dialog.enum';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {

  opcionesSistema = env.OPCIONES_SISTEMA;

  perfiles: Array<Perfil>
  usuarios: Array<Usuario>;
  cargando: boolean = true;

  constructor( 
    public _usuario: UsuarioService,
    private $perfil: PerfilService,
    private $dlg: DialogService ) {
    this.init();
   }

  ngOnInit() { }

  init() {
    this.listar();
  }

  listar() {

    forkJoin(
      this._usuario.listAll(),
      this.$perfil.listAll()
    ).subscribe( (xhrData: any[]) => {
      this.usuarios = xhrData[0]; 
      this.perfiles = xhrData[1]; 
      this.cargando = false; 
    });

  }

  eliminarUsuario( indice: number ) {

    this.$dlg
    .confirm( `¿Desea eliminar al usuario ${this.usuarios[ indice ].nombre }?`, 'Eliminar' )
    .then( (resp: boolean) => {
      if( resp ){
        this._usuario
        .deleteUser( this.usuarios[ indice ]._id )
        .subscribe( (xhrData: any) => {
          this.listar();
        });
      }
    });

  }

  cambiarPassword(indice: number, pass: string){
    this.$dlg
    .confirm( `¿Desea cambiar la contraseña al usuario ${this.usuarios[ indice ].nombre }?`, 'Cambiar contraseña' )
    .then( (resp: boolean) => {
      if( resp ){

        if( pass == ''){
          this.$dlg.alert('Alerta', 'Ingrese el password del usuario a cambiar', DialogEnum.WARNING);
          return;
        }

        let usuarioPaso = this.usuarios[ indice ];
        usuarioPaso.password = pass;

        this._usuario
        .changePasswordUse( usuarioPaso )
        .subscribe( (xhrData: any) => {
          this.$dlg.alert('Atención', 'Contraseña cambiada con éxito', DialogEnum.INFO);
          this.listar();
        });
      }
    });
  }

  opcionesToStr( opciones: string[] ){

    if( (typeof opciones == 'undefined') || (opciones == null) ){
      return "Sin opciones asignadas";
    }

    let opcionesStr = '';

    opciones.forEach( (value: string) => {
      opcionesStr = opcionesStr.concat( ', ', this.opcionesSistema[ value ] )
    });

    if( opcionesStr.length > 0 ){
      opcionesStr = opcionesStr.substr( 2 );
    }

    return opcionesStr;
  }

}
